import React from 'react';
// import Services from './components/Services';

function Footer() {
    return (
        <footer className="siteFooter">
            <div className="container">
                <div className="siteFooter_container">
                    <div className="siteFooter_container__left">
                        <p>2023© - Huckle Legal Accounts Ltd</p>
                        <hr />
                        <small>Registered Office : 8 Medlar Close, Burton, Christchurch, BH23 7PG | <strong>Company Reg: 8305671</strong></small>
                    </div>
                    <div className="siteFooter_container__middle">
                        <ul className="social_list">
                            <li><a href="https://twitter.com/HuckleLegalAccs" target="_blank"><i className="fa-brands fa-square-x-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/in/kate-huckle-57613960/" target="_blank"><i className="fa-brands fa-linkedin"></i></a></li>
                        </ul>
                        <a className="priv_policy" href="/privacy-policy">Privacy Policy</a>
                    </div>
                    <div className="siteFooter_container__right">
                        <p className="madeBy">Made by <strong><a href="https://soup.digital" target="_blank" rel="noreferrer">Soup Digital</a></strong></p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;