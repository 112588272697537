import React from "react";
import useBodyClass from '../hook/useBodyClass';
import data from "../data/aboutme.json";

function About() {
    useBodyClass('page-about');
    const aboutData = data.about;
    const bgImage = aboutData.heroimage;
    return (
        <>
            <div id="about">
                <section className="hero_area" style={{ backgroundImage: 'url(' + bgImage + ')' }}>
                    <div className="container">
                        <div className="hero_container_parent">
                            <div className="hero_left">
                                <h1>{aboutData.herotitle}</h1>
                                <hr className="fixed_width" />
                                <h3>{aboutData.subtitle}</h3>
                            </div>
                            <div className="hero_right"></div>
                        </div>
                    </div>
                </section>

                <section className="main_content">
                    <div class="container">
                        <div className="main_content_container__parent">
                            <div className="main_content_container__left">
                                <div dangerouslySetInnerHTML={{ __html: aboutData.description }}></div>
                            </div>
                            <div className="main_content_container__right">

                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    );
}

export default About;