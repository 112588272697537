import React from 'react';
import Navigation from './Navigation';

function Header() {
    return (
        <header className="header_area">
            <div className="container">
                <div className="header_container">
                    <div className="header_logo">
                        <a className="textLogo" href="/">
                            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 138.02 20.85">
                                <g id="Layer_1-2" data-name="Layer 1">
                                    <g>
                                        <g>
                                            <path class="cls-1" d="m.07,20.75c-.05-.07-.07-.15-.07-.25,0-.12.04-.22.13-.3.09-.08.23-.12.41-.12h1.38V.78H.27c-.08,0-.15-.03-.2-.1-.05-.07-.07-.15-.07-.26,0-.28.17-.42.51-.42h4.44c.14,0,.21.08.21.24,0,.22-.16.35-.48.39l-1.74.18v8.79h13.23V.78h-1.8c-.08,0-.15-.03-.19-.1-.05-.07-.08-.15-.08-.26,0-.28.17-.42.51-.42h4.41c.14,0,.21.08.21.24,0,.22-.16.35-.48.39l-1.56.18v19.23l1.56.18c.32.04.48.17.48.39,0,.16-.07.24-.21.24h-4.65c-.08,0-.15-.03-.19-.1-.05-.07-.08-.15-.08-.25,0-.28.17-.42.51-.42h1.56v-9.63H2.94v9.6l1.74.18c.32.04.48.17.48.39,0,.16-.07.24-.21.24H.27c-.08,0-.15-.03-.2-.1Z" />
                                            <path class="cls-1" d="m23.13,20.58c0-.22.19-.35.57-.39l1.38-.18V.78h-1.65c-.08,0-.15-.03-.19-.1-.05-.07-.08-.15-.08-.26,0-.28.17-.42.51-.42h4.44c.14,0,.21.08.21.24,0,.22-.16.35-.48.39l-1.74.18v19.2h10.41l.21-1.86c.04-.24.09-.41.15-.51s.16-.15.3-.15c.18,0,.27.09.27.27v3.09h-14.07c-.16,0-.24-.09-.24-.27Z" />
                                        </g>
                                        <g>
                                            <path class="cls-1" d="m49.64,12.75v-6.35h.38v2.95h4.27v-2.95h.38v6.35h-.38v-3.04h-4.27v3.04h-.38Z" />
                                            <path class="cls-1" d="m57.82,12.82c-.34,0-.63-.06-.85-.19s-.4-.31-.51-.56c-.11-.25-.17-.55-.17-.92v-2.76h.38v2.73c0,.31.04.56.12.76.08.2.21.35.38.45.17.1.39.14.66.14.29,0,.55-.07.77-.2.22-.13.39-.32.52-.55.13-.24.19-.51.19-.83v-2.49h.38v4.36h-.37v-1.09h.11c-.11.38-.31.67-.6.86-.29.2-.62.3-.99.3Z" />
                                            <path class="cls-1" d="m62.9,12.82c-.41,0-.77-.1-1.07-.29s-.52-.46-.68-.8c-.16-.34-.24-.74-.24-1.2s.08-.85.24-1.18c.16-.33.39-.58.68-.77.3-.18.65-.27,1.07-.27.26,0,.52.05.77.16.25.1.46.25.63.44l-.18.3c-.19-.19-.4-.33-.61-.41-.22-.08-.42-.13-.62-.13-.51,0-.9.16-1.18.49-.28.32-.41.79-.41,1.39s.14,1.07.41,1.41.67.51,1.18.51c.2,0,.4-.04.62-.13.22-.08.42-.22.61-.41l.18.3c-.17.19-.38.33-.64.44s-.51.16-.77.16Z" />
                                            <path class="cls-1" d="m65.3,12.75v-6.35h.38v4.01h.02l2.26-2.02h.54l-2.57,2.28v-.32l2.82,2.4h-.53l-2.51-2.13h-.02v2.13h-.38Z" />
                                            <path class="cls-1" d="m70.7,12.82c-.38,0-.67-.12-.88-.36s-.31-.58-.31-1.02v-5.03h.38v4.97c0,.24.03.44.09.61s.16.29.28.37.28.12.47.12c.08,0,.15,0,.22-.01s.14-.02.2-.04l-.03.35c-.08.02-.15.03-.22.04-.07,0-.14.01-.21.01Z" />
                                            <path class="cls-1" d="m73.79,12.82c-.43,0-.8-.09-1.12-.27-.31-.18-.55-.44-.72-.77s-.26-.73-.26-1.19.09-.85.26-1.19.41-.6.71-.79c.3-.19.65-.28,1.05-.28s.7.08.96.25c.26.17.47.41.61.72s.21.68.21,1.12v.23h-3.55v-.33h3.36l-.15.11c0-.55-.12-.98-.36-1.29-.24-.31-.6-.47-1.08-.47-.35,0-.64.08-.89.25-.24.17-.43.39-.55.67s-.18.6-.18.96v.04c0,.4.07.74.2,1.02.13.28.33.5.58.64.25.15.56.22.91.22.27,0,.52-.04.76-.13.24-.09.47-.23.71-.44l.18.31c-.19.19-.43.33-.72.45-.29.11-.6.17-.91.17Z" />
                                            <path class="cls-1" d="m79,12.75v-6.35h.38v5.99h3.38v.35h-3.76Z" />
                                            <path class="cls-1" d="m85.46,12.82c-.43,0-.8-.09-1.12-.27-.31-.18-.55-.44-.72-.77s-.26-.73-.26-1.19.09-.85.26-1.19.41-.6.71-.79c.3-.19.65-.28,1.05-.28s.7.08.96.25c.26.17.47.41.61.72s.21.68.21,1.12v.23h-3.55v-.33h3.36l-.15.11c0-.55-.12-.98-.36-1.29-.24-.31-.6-.47-1.08-.47-.35,0-.64.08-.89.25-.24.17-.43.39-.55.67s-.18.6-.18.96v.04c0,.4.07.74.2,1.02.13.28.33.5.58.64.25.15.56.22.91.22.27,0,.52-.04.76-.13.24-.09.47-.23.71-.44l.18.31c-.19.19-.43.33-.72.45-.29.11-.6.17-.91.17Z" />
                                            <path class="cls-1" d="m90.2,14.44c-.36,0-.69-.05-1-.16s-.59-.26-.84-.46l.18-.31c.19.14.37.25.54.34s.34.15.53.18c.18.04.39.05.61.05.45,0,.79-.13,1.03-.38s.35-.62.35-1.09v-1.2h.09c-.08.38-.28.69-.58.9-.3.22-.66.33-1.07.33s-.75-.09-1.04-.27-.52-.44-.68-.77c-.16-.33-.24-.7-.24-1.13s.08-.81.24-1.13c.16-.32.39-.58.68-.76.29-.18.64-.27,1.04-.27s.77.11,1.07.33c.3.22.49.52.58.9h-.1v-1.16h.38v4.16c0,.41-.07.76-.2,1.04-.13.28-.33.49-.58.63-.26.14-.58.21-.97.21Zm-.18-2.14c.48,0,.86-.16,1.14-.49.28-.33.42-.77.42-1.32s-.14-.99-.42-1.32c-.28-.33-.66-.49-1.14-.49s-.86.16-1.14.49c-.28.33-.42.77-.42,1.32s.14.99.42,1.32c.28.33.66.49,1.14.49Z" />
                                            <path class="cls-1" d="m94.8,12.82c-.28,0-.54-.06-.77-.17-.23-.11-.41-.27-.54-.46s-.2-.41-.2-.65c0-.32.08-.58.24-.76s.43-.31.82-.39c.39-.08.92-.11,1.6-.11h.37v.33h-.35c-.44,0-.81.01-1.11.04s-.53.08-.69.14c-.17.07-.29.16-.36.27-.07.11-.1.26-.1.43,0,.29.1.52.3.71s.47.27.81.27c.28,0,.52-.07.72-.2.21-.13.37-.32.49-.55s.18-.49.18-.79v-1.03c0-.43-.08-.75-.25-.94-.17-.2-.44-.3-.81-.3-.27,0-.52.04-.76.12-.24.08-.48.2-.74.38l-.18-.32c.16-.11.33-.2.51-.28.18-.08.37-.14.57-.18.2-.04.39-.06.58-.06.33,0,.6.06.82.17.22.11.38.29.48.52.1.23.16.53.16.9v2.83h-.37v-1.09h.1c-.06.24-.16.45-.31.62-.14.17-.32.31-.53.4-.21.09-.44.14-.7.14Z" />
                                            <path class="cls-1" d="m99.3,12.82c-.38,0-.67-.12-.88-.36s-.31-.58-.31-1.02v-5.03h.38v4.97c0,.24.03.44.09.61s.16.29.28.37.28.12.47.12c.08,0,.15,0,.22-.01s.14-.02.2-.04l-.03.35c-.08.02-.15.03-.22.04-.07,0-.14.01-.21.01Z" />
                                            <path class="cls-1" d="m102.35,12.75l2.73-6.35h.36l2.73,6.35h-.4l-.79-1.84.24.08h-3.92l.25-.08-.78,1.84h-.41Zm2.9-5.83l-1.61,3.78-.2-.05h3.62l-.18.05-1.61-3.78h-.02Z" />
                                            <path class="cls-1" d="m110.94,12.82c-.41,0-.77-.1-1.07-.29s-.52-.46-.68-.8c-.16-.34-.24-.74-.24-1.2s.08-.85.24-1.18c.16-.33.39-.58.68-.77.3-.18.65-.27,1.07-.27.26,0,.52.05.77.16.25.1.46.25.63.44l-.18.3c-.19-.19-.4-.33-.61-.41-.22-.08-.42-.13-.62-.13-.51,0-.9.16-1.18.49-.28.32-.41.79-.41,1.39s.14,1.07.41,1.41.67.51,1.18.51c.2,0,.4-.04.62-.13.22-.08.42-.22.61-.41l.18.3c-.17.19-.38.33-.64.44s-.51.16-.77.16Z" />
                                            <path class="cls-1" d="m115.04,12.82c-.41,0-.77-.1-1.07-.29s-.52-.46-.68-.8c-.16-.34-.24-.74-.24-1.2s.08-.85.24-1.18c.16-.33.39-.58.68-.77.3-.18.65-.27,1.07-.27.26,0,.52.05.77.16.25.1.46.25.63.44l-.18.3c-.19-.19-.4-.33-.61-.41-.22-.08-.42-.13-.62-.13-.51,0-.9.16-1.18.49-.28.32-.41.79-.41,1.39s.14,1.07.41,1.41.67.51,1.18.51c.2,0,.4-.04.62-.13.22-.08.42-.22.61-.41l.18.3c-.17.19-.38.33-.64.44s-.51.16-.77.16Z" />
                                            <path class="cls-1" d="m119.09,12.82c-.4,0-.74-.09-1.03-.28s-.52-.45-.68-.78c-.16-.34-.24-.73-.24-1.19s.08-.85.24-1.19c.16-.34.38-.6.68-.78s.63-.28,1.03-.28.74.09,1.03.28.52.45.67.78c.16.34.24.73.24,1.19s-.08.85-.24,1.19c-.16.34-.38.6-.67.78s-.63.28-1.03.28Zm0-.35c.48,0,.86-.17,1.14-.5.28-.33.42-.8.42-1.4s-.14-1.07-.42-1.4c-.28-.33-.66-.5-1.14-.5s-.85.17-1.13.5c-.28.33-.42.8-.42,1.4s.14,1.07.42,1.4c.28.33.66.5,1.13.5Z" />
                                            <path class="cls-1" d="m123.8,12.82c-.34,0-.63-.06-.85-.19s-.4-.31-.51-.56c-.11-.25-.17-.55-.17-.92v-2.76h.38v2.73c0,.31.04.56.12.76.08.2.21.35.38.45.17.1.39.14.66.14.29,0,.55-.07.77-.2.22-.13.39-.32.52-.55.13-.24.19-.51.19-.83v-2.49h.38v4.36h-.37v-1.09h.11c-.11.38-.31.67-.6.86-.29.2-.62.3-.99.3Z" />
                                            <path class="cls-1" d="m127.18,12.75v-4.36h.37v1.06h-.1c.12-.38.33-.66.62-.85s.63-.28,1.02-.28c.35,0,.64.06.87.18s.4.3.52.55c.11.25.17.56.17.93v2.77h-.38v-2.74c0-.31-.04-.56-.12-.76-.08-.2-.21-.34-.38-.44-.17-.1-.4-.14-.68-.14-.31,0-.57.06-.81.19-.23.13-.41.31-.54.55-.13.24-.19.51-.19.83v2.5h-.38Z" />
                                            <path class="cls-1" d="m133.69,12.82c-.46,0-.8-.13-1.03-.39s-.34-.62-.34-1.09v-2.63h-.87v-.32h.87v-1.36h.38v1.36h1.43v.32h-1.43v2.55c0,.39.08.69.23.9.16.21.42.31.78.31.11,0,.21-.01.31-.04.1-.03.18-.05.25-.08l.09.31c-.05.04-.15.07-.29.1-.14.03-.27.05-.39.05Z" />
                                            <path class="cls-1" d="m136.4,12.82c-.2,0-.41-.02-.61-.07-.2-.04-.39-.11-.56-.2s-.32-.19-.45-.31l.18-.31c.14.13.3.23.45.31s.32.13.49.17c.17.04.34.05.51.05.39,0,.69-.08.9-.23s.31-.35.31-.61c0-.2-.07-.36-.2-.48-.13-.12-.35-.21-.65-.28l-.75-.18c-.38-.09-.66-.23-.83-.41-.18-.19-.27-.43-.27-.72,0-.25.06-.46.19-.65.13-.19.31-.33.55-.43s.51-.15.82-.15c.2,0,.39.02.57.07s.35.11.5.2.28.19.38.32l-.18.31c-.11-.12-.24-.22-.38-.3-.14-.08-.28-.14-.43-.18-.15-.04-.31-.06-.47-.06-.37,0-.65.08-.85.24-.2.16-.31.37-.31.63,0,.2.06.37.19.5s.33.22.6.29l.75.18c.39.1.68.23.87.41s.29.41.29.7c0,.25-.07.46-.2.64-.13.18-.32.32-.57.42-.24.1-.53.15-.86.15Z" />
                                        </g>
                                        <line class="cls-2" x1="43.63" y1="2.43" x2="43.63" y2="18.43" />
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                    <div className="header_navigation">
                        <Navigation />
                    </div>
                    <div className="header_contact">
                        {/* <a className="telNo" href="tel:000000">000000</a> */}
                        <a className="emailAddress" href="/contact">info&#64;hucklelegalaccounts.co.uk</a>
                    </div>
                </div>
            </div>

        </header>
    );
}

export default Header;