import React, { useState } from "react";
import useBodyClass from '../hook/useBodyClass';
import data from "../data/contact.json";
import ContactForm from "./ContactForm";





function Contact() {
    useBodyClass('page-contact');
    const contactData = data.contact;
    const bgImage = contactData.heroimage;
    // In Contact.js
    // const isProduction = process.env.NODE_ENV === 'production';
    // const basePath = isProduction ? '/public_html' : '';
    // const bgImage = `${basePath}${contactData.heroimage}`;
    return (
        <>
            <div id="contact">
                <section className="hero_area" style={{ backgroundImage: 'url("' + bgImage + '")' }}>
                    <div className="container">
                        <div className="hero_container_parent">
                            <div className="hero_left">
                                <h1>{contactData.herotitle}</h1>
                                <hr className="fixed_width" />
                                <h3>{contactData.subtitle}</h3>
                            </div>
                            <div className="hero_right"></div>
                        </div>
                    </div>
                </section>

                <section className="main_content">
                    <div class="container">
                        <div className="main_content_container__parent">
                            <div className="main_content_container__left">
                                <div dangerouslySetInnerHTML={{ __html: contactData.description }}></div>

                                <ContactForm />
                            </div>
                            <div className="main_content_container__right">


                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    );
}

export default Contact;