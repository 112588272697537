import React from "react";
import useBodyClass from '../hook/useBodyClass';
import data from "../data/homepage.json";


function Home() {
    useBodyClass('page-home');
    const homeData = data.home;

    const bgImage = homeData.heroimage;

    return (
        <>
            <div id="home">

                <section className="hero_area" style={{ backgroundImage: 'url(' + bgImage + ')' }}>
                    <div className="container">
                        <div className="hero_container_parent">
                            <div className="hero_left">
                                <h1>{homeData.herotitle}</h1>
                                <hr className="fixed_width" />
                                <h3>{homeData.subtitle}</h3>
                            </div>
                            <div className="hero_right"></div>
                        </div>
                    </div>
                </section>

                <section className="main_content">
                    <div class="container">
                        <div className="main_content_container__parent">
                            <div className="main_content_container__left">
                                <h2>{homeData.welcome}</h2>
                                <div dangerouslySetInnerHTML={{ __html: homeData.description }}></div>
                            </div>
                            <div className="main_content_container__right">
                                <blockquote>
                                    <p>{homeData.quote}</p>
                                    <cite>{homeData.quoteAuthor}</cite>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="core_services">
                    <div className="container">
                        <h2>{homeData.coreServicesTitle}</h2>
                        <hr className="fixed_width" />
                        <div className="core_services_container">
                            <div className="core_services_container__item">
                                <i class="intoIcon fa-solid fa-comments"></i>
                                <h3>{homeData.coreServicesItem1Title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: homeData.coreServicesItem1Description }}></div>
                            </div>
                            <div className="core_services_container__item">
                                <i class="intoIcon fa-solid fa-file-invoice-dollar"></i>
                                <h3>{homeData.coreServicesItem2Title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: homeData.coreServicesItem2Description }}></div>
                            </div>
                            <div className="core_services_container__item">
                                <i class="intoIcon fa-solid fa-hand-holding-dollar"></i>
                                <h3>{homeData.coreServicesItem3Title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: homeData.coreServicesItem3Description }}></div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        </>
    );
}

export default Home;