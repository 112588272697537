import React from "react";
import useBodyClass from '../hook/useBodyClass';
import data from "../data/services.json";

function Services() {
    useBodyClass('page-services');
    const serviceData = data.services;

    return (
        <>
            <div id="services">
                <section className="hero_area" style={{ backgroundImage: `url(${serviceData.heroimage})` }}>
                    <div className="container">
                        <div className="hero_container_parent">
                            <div className="hero_left">
                                <h1>{serviceData.herotitle}</h1>
                                <hr className="fixed_width" />
                                <h3>{serviceData.subtitle}</h3>
                            </div>
                            <div className="hero_right"></div>
                        </div>
                    </div>
                </section>

                <section className="main_content">
                    <div class="container">
                        <div className="main_content_container__parent">
                            <div className="main_content_container__left">
                                <div dangerouslySetInnerHTML={{ __html: serviceData.description }}></div>
                            </div>
                            <div className="main_content_container__right">

                            </div>
                        </div>
                    </div>
                </section>

                <section className="core_services">
                    <div className="container">
                        <h2>{serviceData.coreServicesTitle}</h2>
                        <hr className="fixed_width" />
                        <div className="core_services_container">
                            <div className="core_services_container__item">
                                <i class="intoIcon fa-solid fa-comments"></i>
                                <h3>{serviceData.coreServicesItem1Title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: serviceData.coreServicesItem1Description }}></div>
                            </div>
                            <div className="core_services_container__item">
                                <i class="intoIcon fa-solid fa-file-invoice-dollar"></i>
                                <h3>{serviceData.coreServicesItem2Title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: serviceData.coreServicesItem2Description }}></div>
                            </div>
                            <div className="core_services_container__item">
                                <i class="intoIcon fa-solid fa-hand-holding-dollar"></i>
                                <h3>{serviceData.coreServicesItem3Title}</h3>
                                <div dangerouslySetInnerHTML={{ __html: serviceData.coreServicesItem3Description }}></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    );
}

export default Services;