import { useLayoutEffect } from 'react';

export default function useBodyClass(className) {
    useLayoutEffect(() => {
        // Add the class to body
        document.body.classList.add(className);

        // Cleanup function to remove the class when component unmounts
        return () => document.body.classList.remove(className);
    }, [className]); // Only re-run if className changes
}
