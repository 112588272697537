import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

function ContactForm() {

    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        telephone: '',
        company: '',
        selectOption: '',
        radioChoice: ''
    });

    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validate = () => {
        let tempErrors = {};

        if (!formData.name.trim()) tempErrors.name = "Name is required.";
        if (!formData.email.trim()) tempErrors.email = "Email is required.";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) tempErrors.email = "Email is not valid.";
        if (!formData.telephone.trim()) tempErrors.telephone = "Phone is required.";
        if (!formData.company.trim()) tempErrors.company = "Company is required.";
        if (!formData.message.trim()) tempErrors.message = "Message is required.";
        if (!formData.radioChoice) tempErrors.radioChoice = "Please select an option.";
        if (!formData.selectOption) tempErrors.selectOption = "Please select an option.";
        setErrors(tempErrors);

        // If no errors, return true, else false
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) return;  // If validation fails, stop here

        try {
            const response = await fetch('../sendEmail.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...formData,
                    recaptchaValue: recaptchaValue
                })
            });

            const responseData = await response.json();

            if (responseData.status === 'success') {
                setSuccessMessage(responseData.message);
                setFormData({
                    name: '',
                    email: '',
                    company: '',
                    telephone: '',
                    selectOption: '',
                    radioChoice: '',
                    message: ''
                });
                setErrors({});
                setRecaptchaValue(null); // Also reset the reCAPTCHA value
                setErrorMessage("");
            } else {
                setErrorMessage(responseData.message)
                setErrors({ form: "Error sending email." });
            }
        } catch (error) {
            console.error('There was an error sending the email.', error);
        }
    }

    return (
        <form className="contact_form" onSubmit={handleSubmit}>
            <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
            />
            {errors.name && <p className="error">{errors.name}</p>}

            <input
                type="text"
                name="company"
                placeholder="Company"
                value={formData.company}
                onChange={handleChange}
            />
            {errors.company && <p className="error">{errors.company}</p>}

            <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
            />
            {errors.email && <p className="error">{errors.email}</p>}

            <input
                type="text"
                name="telephone"
                placeholder="Telephone"
                value={formData.telephone}
                onChange={handleChange}
            />
            {errors.telephone && <p className="error">{errors.telephone}</p>}

            <select name="selectOption" value={formData.selectOption} onChange={handleChange}>
                <option value="" disabled>--Please choose a service--</option>
                <option value="COFA Consultancy">COFA Consultancy</option>
                <option value="Criminal Legal Aid Billing">Criminal Legal Aid Billing</option>
                <option value="Legal Cashier Locum">Legal Cashier Locum</option>
            </select>
            {errors.selectOption && <p className="error">{errors.selectOption}</p>}

            <div className="book_appointment">
                <p>Would you like to book an appointment?</p>
                <label>
                    <input
                        type="radio"
                        name="radioChoice"
                        value="yes"
                        checked={formData.radioChoice === "yes"}
                        onChange={handleChange}
                    />
                    Yes
                </label>
                <label>
                    <input
                        type="radio"
                        name="radioChoice"
                        value="no"
                        checked={formData.radioChoice === "no"}
                        onChange={handleChange}
                    />
                    No
                </label>
                {errors.radioChoice && <p className="error">{errors.radioChoice}</p>}
            </div>
            <textarea
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
            />
            {errors.message && <p className="error">{errors.message}</p>}
            <br />
            <center>
                <ReCAPTCHA
                    sitekey="6Le-SrAoAAAAAAmjnqjyNJ2vafmIdEyX83sNnSho"
                    onChange={value => setRecaptchaValue(value)}
                />
            </center>
            <button className="cta_btn" type="submit">Send</button>

            {successMessage && <p className="success">{successMessage}</p>}
            {errorMessage && <p className="error">{errorMessage}</p>}
        </form>
    )
}

export default ContactForm;