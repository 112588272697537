import React from "react";
import useBodyClass from '../hook/useBodyClass';
import data from "../data/privacypolicy.json";

function PrivacyPolicy() {
    useBodyClass('page-privacy-policy');
    const privacyData = data.privacypolicy;
    const bgImage = privacyData.heroimage;

    return (
        <>
            <div id="privacy-policy">
                <section className="hero_area" style={{ backgroundImage: 'url(' + bgImage + ')' }}>
                    <div className="container">
                        <div className="hero_container_parent">
                            <div className="hero_left">
                                <h1>{privacyData.herotitle}</h1>
                                <hr className="fixed_width" />
                            </div>
                            <div className="hero_right"></div>
                        </div>
                    </div>
                </section>

                <section className="main_content">
                    <div class="container">
                        <div className="main_content_container__parent">
                            <div className="main_content_container__left">
                                <p>Huckle Legal Accounts Ltd is committed to preserving the privacy of all visitors to our website at www.hucklelegalaccounts.co.uk. Please read the following privacy policy to understand how we use and protect the information that you provide to us.</p>
                                <p>For the purposes of the Data protection Act 1998, the Data Controller is Huckle Legal Accounts Ltd.</p>

                                <p>By registering on this website, you consent to the collection, use and transfer of your information under the terms of this policy.</p>

                                <h3>INFORMATION THAT WE COLLECT FROM YOU</h3>
                                <p>When you visit or register on www.hucklelegalaccounts.co.uk you may be asked to provide certain information about yourself including your name and contact details.</p>

                                <p>We may also collect information about your usage of our website as well as information about you from messages you post to the website and e-mails or letters you send to us.</p>

                                <h3>USE OF YOUR INFORMATION</h3>
                                <p>Your information will enable us to provide you with access to all parts of our website and to supply the services or information you have requested. It will enable you to participate in interactive features of our service, when you choose to do so. We will also use and analyse the information we collect so that we can administer, support, improve and develop our business.</p>

                                <p>In particular, we may use your information to contact you for your views on our services and to notify you occasionally about important changes or developments to the website or our services. Further, where you have consented, we might also use your information to let you know by email about other products and services which we offer which may be of interest to you. If you change your mind about being contacted in the future, please let us know.</p>

                                <h3>DISCLOSURE OF YOUR INFORMATION</h3>
                                <p>The information you provide will not be given to third parties.</p>

                                <p>Where you have consented when providing us with your details, we may contact you occasionally about products and services which may be of interest to you. If you change your mind about being contacted by us in the future, please let us know at info@hucklelegalaccounts.co.uk.</p>

                                <p>Finally, if our business enters into a joint venture with or is sold to or merged with another business entity, your information may be disclosed to our new business partners or owners.</p>

                                <h3>SECURITY AND DATA RETENTION</h3>
                                <p>We employ security measures to protect, so far as reasonably possible, your information from access by unauthorised persons and against unlawful processing, accidental loss, destruction and damage. We will retain your information for a reasonable period or as long as the law requires or permits.</p>

                                <h3>CHANGES TO OUR PRIVACY POLICY</h3>
                                <p>Any changes to our privacy policy in the future will be posted to the website and, where appropriate, through e-mail notification.</p>

                                <h3>CONTACT</h3>
                                <p>All comments, queries and requests relating to our use of your information are welcomed and should be addressed to info@hucklelegalaccounts.co.uk</p>

                                <h3>COOKIES - WHAT ARE COOKIES</h3>
                                <p>Cookies are tiny text files stored on your computer when you visit certain web pages. We use cookies to keep track of what you have in your basket, and to remember you when you return to our site.</p>

                                <p>To order products, you need to have cookies enabled - see the section on Managing cookies. If you don't wish to enable cookies, you'll still be able to browse the site and use it for research purposes. Most web browsers have cookies enabled, but see Managing cookies for help to turn them on should you need to.</p>

                                <p>Please note that cookies can't harm your computer. We don't store personally identifiable information such as credit card details in cookies we create.</p>

                                <p>We're giving you this information as part of our initiative to comply with recent legislation, and to make sure we're honest and clear about your privacy when using our website.</p>

                                <h3>THIRD PARTY COOKIES</h3>
                                <p>When you visit our site you may notice some cookies that aren't related to us. If you go on to a web page that contains embedded content, for example from YouTube, you may be sent cookies from these websites. We don't control the setting of these cookies, so we suggest you check the third-party websites for more information about their cookies and how to manage them.</p>

                                <h3>MANAGING COOKIES</h3>
                                <p>If cookies aren't enabled on your computer, it will mean that your shopping experience on our website will be limited to browsing and researching; you won't be able to add products to your basket and buy them.</p>

                                <h3>TO ENABLE COOKIES</h3>
                                <p>If you're not sure of the type and version of web browser you use to access the Internet:</p>

                                <p>For PCs: click on 'Help' at the top of your browser window and select the 'About' option</p>
                                <p>For Macs: with the browser window open, click on the Apple menu and select the 'About' option</p>

                                <h3>HOW TO CHECK COOKIES ARE ENABLED FOR PCS</h3>
                                <p>Please refer to your browsers user guide or help section for the steps to enable/disable cookies.</p>
                            </div>
                            <div className="main_content_container__right">

                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    );
}

export default PrivacyPolicy;